import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  public apiHostUrl;
  constructor() {
    if (environment.production == true) {
      //http://localhost/hpposternew/api/web/v1
      //this.apiHostUrl = 'http://localhost/classmate-api/web/v1';
      //this.apiHostUrl = 'http://api.staging5.bigcityvoucher.co.in/v1';
       this.apiHostUrl = 'https://api3.staging4.bigcityvoucher.co.in/v1';
      //this.apiHostUrl = 'https://api.createwithclassmate.com/v1';
    } else {
      //this.apiHostUrl = 'http://localhost/classmate-api/web/v1';
      //this.apiHostUrl = 'http://api.staging5.bigcityvoucher.co.in/v1';
       this.apiHostUrl = 'https://api3.staging4.bigcityvoucher.co.in/v1';
       //this.apiHostUrl = 'https://api.createwithclassmate.com/v1';
    }
  }
}
