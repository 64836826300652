import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { LoginForm, OTPModel } from '../Models/login-form';
import { Observable, of } from 'rxjs';
import { throwError } from 'rxjs/internal/observable/throwError';
import { map, catchError } from 'rxjs/operators';
import { GlobalService } from './SiteAuth/global.service';

// Mock For Rewards;

import { REWARDS, REWARDENTRIES, WEEKTASK, DASHBOARDCOUNT, CITY, POSITION, WEEK, CATEGORY, RATING } from "../Models/mock-entries";
import { WeekEnttries } from '../Models/WeekEntries';

const login = '/moderator/teacherphonenumber';
const otp = '/moderator/teachersotp';
const reward = '/user/getrewardslist';
const gradesAPI = '/user/grades';
const collagesData = '/user/getcollageassigneddata';
const competitaionData = '/moderator/schoolcompetationdata';
const updateWinnerEntry = '/moderator/updateentry';
const parameters = '/user/ratingparameters';
const cityAPI = '/user/getcitylist';
const SchoolAPI = '/user/schools';
const tasksAPI = '/user/moderatortasks';
const documentAPI = '/user/campaigndocumentlist';
const campaignAPI = '/user/campaigns';
const faqAPI = '/user/campaignfaqlist';
const DASHBOARDAPI = '/moderator/getdashboard';
const COMPETATION_DATA = '/moderator/getallentries';
const WINNER_TYPE_DATA = '/moderator/getwinnertype';
const PUBLISH_THE_WINNER = '/moderator/publishwinner';
const WINNER_LIST = '/moderator/getallwinnerentries';
@Injectable({
  providedIn: 'root'
})

export class UserService {

  public CampaignId: number = 1;

  school_id : any;

  constructor(private http: HttpClient, private siteAuth: GlobalService) {

  }

  private getHeaders() {

    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    });
  }

  userLogin(LoginForm: LoginForm): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + login;
    let body = JSON.stringify(LoginForm);
    return this.http.post<any>(apiUrl, body, { headers: headers })
      .pipe(
        map(
          response => {
            return response;
          }
        ),
        catchError(this.handleError)
      )
  }

  userOTP(LoginForm: OTPModel): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + otp;
    let body = JSON.stringify(LoginForm);
    return this.http.post<any>(apiUrl, body, { headers: headers })
      .pipe(
        map(
          response => {
            return response;
          }
        ),
        catchError(this.handleError)
      )
  }

  grades(): Observable<any> {

    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + gradesAPI + '?campaign_id=' + this.CampaignId;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response;
          }
        ),
        catchError(this.handleError)
      )
  }

  getRewardsOfCampaign(): Observable<any> {
    return of(REWARDENTRIES);
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + reward + '?campaign_id=' + this.CampaignId;

    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response;
          }
        ),
        catchError(this.handleError)
      )
  }

  getRewardsEntriesData(): Observable<any> {
    return of(REWARDENTRIES);
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + collagesData;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response;
          }
        ),
        catchError(this.handleError)
      )
  }

  getWeekEntriesData(): Observable<any> {
    this.school_id = localStorage.getItem('school_id');
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + competitaionData + "?school_id="+ this.school_id;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response;
          }
        ),
        catchError(this.handleError)
      )
  }

  updateWeekEntry(entry: WeekEnttries): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + updateWinnerEntry;
    let body = JSON.stringify(entry);
    return this.http.post<any>(apiUrl, body, { headers: headers })
      .pipe(
        map(
          response => {
            return response;
          }
        ),
        catchError(this.handleError)
      )
  }

  getTaskEntriesData(): Observable<any> {
     return of(WEEKTASK);
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + tasksAPI;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response;
          }
        ),
        catchError(this.handleError)
      )
  }

  getDashboardData(): Observable<any> {
    return of(DASHBOARDCOUNT)
  }

  getRelatedDocs(): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + documentAPI + '?campaign_id=' + this.CampaignId;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response;
          }
        ),
        catchError(this.handleError)
      )
  }

  getFaQData(): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + faqAPI + '?campaign_id=' + this.CampaignId;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response;
          }
        ),
        catchError(this.handleError)
      )
  }

  getAllCampaigns(): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + campaignAPI;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response;
          }
        ),
        catchError(this.handleError)
      )
  }

  getCityData(): Observable<any> {
    //return of(CITY)
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + cityAPI;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response;
          }
        ),
        catchError(this.handleError)
      )
  }

  getSchoolData(): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + SchoolAPI + '?campaign_id=1';
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response;
          }
        ),
        catchError(this.handleError)
      )
  }

  getRatingEntries(): Observable<any> {
    return of(RATING)
  }

  getPositionData(): Observable<any> {
    return of(POSITION)
  }

  getWeeks(): Observable<any> {
    return of(WEEK)
  }

  getCategory(): Observable<any> {
    return of(CATEGORY)
  }

  getRatingParameter(): Observable<any> {
    //return of (RATING)
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + parameters;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response.data;
          }
        ),
        catchError(this.handleError)
      )
  }


  //  New Functions;

  getDashboardDetails(): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + DASHBOARDAPI + '?campaign_id=1';
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response.data;
          }
        ),
        catchError(this.handleError)
      )
  }

  getCompetationDetails(gradeId: number): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + COMPETATION_DATA + '?campaign_id=1&grade_id='+gradeId;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response.data;
          }
        ),
        catchError(this.handleError)
      )
  }

  getWinnerType(): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + WINNER_TYPE_DATA + '?campaign_id=1';
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response.data;
          }
        ),
        catchError(this.handleError)
      )
  }

  publishWinner(gradeId: number, winners : any): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + PUBLISH_THE_WINNER;
    let body = JSON.stringify({
      'campaign_id': 1,
      'grade_id' : gradeId,
      'winners' : winners
    });
    return this.http.post<any>(apiUrl, body, { headers: headers })
      .pipe(
        map(
          response => {
            return response;
          }
        ),
        catchError(this.handleError)
      )
  }

  WinnerList(): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + WINNER_LIST + '?campaign_id=' + this.CampaignId;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
        map(
          response => {
            return response;
          }
        ),
        catchError(this.handleError)
      )
  }

  private handleError(response: any) {

    let errorMessage: any = {};
    // Connection error
    console.log(response);

    if (response.status === 0) {
      errorMessage = {
        success: false,
        status: 0,
        data: 'Sorry, there was a connection error occurred. Please try again.'
      };
    } else {
      errorMessage = response.error;
    }

    return throwError(errorMessage);
  }

}
