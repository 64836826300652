import {
  trigger,
  transition,
  query,
  stagger,
  animate,
  style,
} from "@angular/animations";
import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { WeekEnttries, WinnerTypeModel } from "src/app/Models/WeekEntries";
import { WeekEntriesProvider } from "src/app/Providers/WeekEntriesProvider";
declare var $;
@Component({
  selector: "app-shortlisted-entries",
  templateUrl: "./shortlisted-entries.component.html",
  styleUrls: ["./shortlisted-entries.component.scss"],
  animations: [
    trigger("listAnimation", [
      transition("* => *", [
        // each time the binding value changes
        query(
          ":leave",
          [stagger(100, [animate("0.2s", style({ opacity: 0 }))])],
          { optional: true }
        ),
        query(
          ":enter",
          [
            style({ opacity: 0 }),
            stagger(100, [animate("0.2s", style({ opacity: 1 }))]),
          ],
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class ShortlistedEntriesComponent implements OnInit {
  @Input() model: WeekEnttries = new WeekEnttries();
  shortlistedEntries: WeekEnttries[];
  gradeNumber: any;
  allEntries: WeekEnttries[];
  rejectedEntries: WeekEnttries[];
  selectedInput: number = 2;
  item: number;
  isShow: boolean;
  selectWinner: boolean = false;
  winnerType: WinnerTypeModel[];
  selectedWinner: number;
  confirmWinner: boolean = false;
  isWinnerShow: boolean = false;
  grade: number;
  isPublished: boolean = false;
  // TODO: Cross browsing0
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  constructor(
    private activateRoute: ActivatedRoute,
    private router: Router,
    private weekEntriesProvider: WeekEntriesProvider
  ) {}

  ngOnInit() {
    // (document.querySelector('.footer') as HTMLElement).style.position = 'fixed';
    this.activateRoute.paramMap.subscribe((paramMap) => {
      if (!paramMap.has("gradeId")) {
        this.router.navigateByUrl("/entries");
      } else {
        this.gradeNumber = paramMap.get("gradeId");
        this.grade = +this.gradeNumber + 1;
        // console.log(`get the entries based on the grade ${paramMap.get('gradeId')}`);
        this.weekEntriesProvider.getCompetationEntries(paramMap.get("gradeId"));
        this.weekEntriesProvider.getWinnerType();
      }
    });
  }

  backClicked() {
    this.router.navigate(["/entries/dashboard"]);
  }

  ngDoCheck() {
    this.isWinnerShow = false;
    this.isPublished = this.weekEntriesProvider.isPublished;
    this.allEntries = this.weekEntriesProvider.allCompetationEntries;
    this.shortlistedEntries = this.weekEntriesProvider.shortlistedEntries;
    this.rejectedEntries = this.weekEntriesProvider.rejectedEntries;
    if (typeof this.shortlistedEntries != "undefined") {
      let winnerOne = this.shortlistedEntries.filter(
        (m) => m.winner_id != null && m.isShortlisted == true
      );
      if (
        typeof winnerOne != "undefined"
      ) {
        if (winnerOne.length > 0) {
          this.isWinnerShow = true;
        }
      }
    }
  }

  onNavigate(item) {
    switch (item) {
      case "1":
        this.router.navigate(["/entries/all-entries", this.gradeNumber]);
        break;
      case "2":
        this.router.navigate([
          "/entries/shortlisted-entries",
          this.gradeNumber,
        ]);
        break;
      case "3":
        this.router.navigate(["/entries/rejected-entries", this.gradeNumber]);
        break;
    }
  }

  ViewEntry(item) {
    this.router.navigate(["/entries/view-entry"], {
      queryParams: { gradeId: this.gradeNumber, entryId: item.id },
    });
  }

  /***************************************************/

  selectWinnerClicked(entries) {
    this.item = entries.id;
    this.selectWinner = true;
    this.winnerType = this.weekEntriesProvider.getWinnerTypeBasedOnGrade(
      this.gradeNumber
    );
    this.model = entries;
    this.model.isWinnerSelected = true;
    this.shortlistedEntries.find(
      (w) => w.id === entries.id
    ).isWinnerSelected = true;
  }

  winnerName: string;
  confirmedWinner: boolean = false;
  removeWinner: boolean = false;

  winnerChanged(item, entries) {
    this.item = entries.id;
    this.selectedWinner = parseInt(item.target.value);
    this.confirmWinner = true;
    this.winnerName = this.winnerType.find(
      (i) => i.id == this.selectedWinner
    ).name;
  }

  confirmedWinnerClicked(entries) {
    this.confirmWinner = false;
    this.shortlistedEntries.find((w) => w.id === entries.id).isConfirmed = true;
    this.model = this.shortlistedEntries.find((w) => w.id == entries.id);
    this.model.winner_id = this.selectedWinner;
    this.item = null;
    this.selectWinner = false;
    this.removeWinner = false;
    this.weekEntriesProvider.UpdateWeekEntry1(this.model, this.model.grade_id);
  }

  removeWinnerClicked(entries) {
    this.removeWinner = true;
    this.item = entries.id;
  }

  noConfirmedWinnerClicked(entries) {
    this.item = null;
    this.confirmWinner = false;
    this.selectWinner = false;
  }

  unconfirmedWinnerClicked(entries) {
    this.item = null;
  }

  removeWinnerYesClicked(entries) {
    this.shortlistedEntries.find(
      (w) => w.id === entries.id
    ).isConfirmed = false;
    this.model = this.shortlistedEntries.find((w) => w.id == entries.id);
    this.model.winner_id = null;
    this.removeWinner = false;
    this.item = null;
    this.weekEntriesProvider.UpdateWeekEntry1(this.model, this.model.grade_id);
    this.isShow = false;
  }

  removeWinnerNoClicked(item) {
    this.removeWinner = false;
    this.item = null;
  }

  /****************************************************** */

  publishWinner() {
    let winners = this.shortlistedEntries.filter(
      (s) => s.winner_status != 1 && s.winner_id != null
    );
    this.weekEntriesProvider.publishWinnerByGrade(this.gradeNumber, winners);
    // this.router.navigate(["/entries/all-entries", this.gradeNumber]);
  }

  /***************************************************************/

  // ANIMATION

  successPublished() {
    var SCREEN_WIDTH = window.innerWidth,
      SCREEN_HEIGHT = window.innerHeight,
      mousePos = {
        x: 400,
        y: 300,
      },
      // CREATE CANVAS
      canvasParent = document.getElementById("publishWinner"),
      canvas = document.createElement("canvas"),
      context = canvas.getContext("2d"),
      particles = [],
      rockets = [],
      MAX_PARTICLES = 400,
      colorCode = 0;
    canvasParent.appendChild(canvas);
    // debugger

    // init
    $(document).ready(function () {
      document.body.appendChild(canvas);
      canvas.width = SCREEN_WIDTH;
      canvas.height = SCREEN_HEIGHT;
      setInterval(launch, 800);
      setInterval(loop, 1000 / 50);
    });

    // update mouse position
    $(document).mousemove(function (e) {
      e.preventDefault();
      mousePos = {
        x: e.clientX,
        y: e.clientY,
      };
    });

    // launch more rockets!!!
    $(document).mousedown(function (e) {
      for (var i = 0; i < 5; i++) {
        launchFrom((Math.random() * SCREEN_WIDTH * 2) / 3 + SCREEN_WIDTH / 6);
      }
    });

    function launch() {
      launchFrom(mousePos.x);
    }

    function launchFrom(x) {
      if (rockets.length < 10) {
        var rocket = new Rocket(x);
        rocket.explosionColor = Math.floor((Math.random() * 360) / 10) * 10;
        rocket.vel.y = Math.random() * -3 - 4;
        rocket.vel.x = Math.random() * 6 - 3;
        rocket.size = 8;
        rocket.shrink = 0.999;
        rocket.gravity = 0.02;
        rockets.push(rocket);
      }
    }

    function loop() {
      // update screen size
      if (SCREEN_WIDTH != window.innerWidth) {
        canvas.width = SCREEN_WIDTH = window.innerWidth;
      }
      if (SCREEN_HEIGHT != window.innerHeight) {
        canvas.height = SCREEN_HEIGHT = window.innerHeight;
      }

      // clear canvas
      context.fillStyle = "rgba(0, 0, 0, 0.02)";
      context.fillRect(0, 0, SCREEN_WIDTH, SCREEN_HEIGHT);

      var existingRockets = [];

      for (var i = 0; i < rockets.length; i++) {
        // update and render
        rockets[i].update();
        rockets[i].render(context);

        // calculate distance with Pythagoras
        var distance = Math.sqrt(
          Math.pow(mousePos.x - rockets[i].pos.x, 2) +
            Math.pow(mousePos.y - rockets[i].pos.y, 2)
        );

        // random chance of 1% if rockets is above the middle
        var randomChance =
          rockets[i].pos.y < (SCREEN_HEIGHT * 2) / 3
            ? Math.random() * 100 <= 1
            : false;

        /* Explosion rules
             - 80% of screen
            - going down
            - close to the mouse
            - 1% chance of random explosion
        */
        if (
          rockets[i].pos.y < SCREEN_HEIGHT / 5 ||
          rockets[i].vel.y >= 0 ||
          distance < 50 ||
          randomChance
        ) {
          rockets[i].explode();
        } else {
          existingRockets.push(rockets[i]);
        }
      }

      rockets = existingRockets;

      var existingParticles = [];

      for (var i = 0; i < particles.length; i++) {
        particles[i].update();

        // render and save particles that can be rendered
        if (particles[i].exists()) {
          particles[i].render(context);
          existingParticles.push(particles[i]);
        }
      }

      // update array with existing particles - old particles should be garbage collected
      particles = existingParticles;

      while (particles.length > MAX_PARTICLES) {
        particles.shift();
      }
    }

    function Particle(pos) {
      this.pos = {
        x: pos ? pos.x : 0,
        y: pos ? pos.y : 0,
      };
      this.vel = {
        x: 0,
        y: 0,
      };
      this.shrink = 0.97;
      this.size = 2;

      this.resistance = 1;
      this.gravity = 0;

      this.flick = false;

      this.alpha = 1;
      this.fade = 0;
      this.color = 0;
    }

    Particle.prototype.update = function () {
      // apply resistance
      this.vel.x *= this.resistance;
      this.vel.y *= this.resistance;

      // gravity down
      this.vel.y += this.gravity;

      // update position based on speed
      this.pos.x += this.vel.x;
      this.pos.y += this.vel.y;

      // shrink
      this.size *= this.shrink;

      // fade out
      this.alpha -= this.fade;
    };

    Particle.prototype.render = function (c) {
      if (!this.exists()) {
        return;
      }

      c.save();

      c.globalCompositeOperation = "lighter";

      var x = this.pos.x,
        y = this.pos.y,
        r = this.size / 2;

      var gradient = c.createRadialGradient(x, y, 0.1, x, y, r);
      gradient.addColorStop(0.1, "rgba(255,255,255," + this.alpha + ")");
      gradient.addColorStop(
        0.8,
        "hsla(" + this.color + ", 100%, 50%, " + this.alpha + ")"
      );
      gradient.addColorStop(1, "hsla(" + this.color + ", 100%, 50%, 0.1)");

      c.fillStyle = gradient;

      c.beginPath();
      c.arc(
        this.pos.x,
        this.pos.y,
        this.flick ? Math.random() * this.size : this.size,
        0,
        Math.PI * 2,
        true
      );
      c.closePath();
      c.fill();

      c.restore();
    };

    Particle.prototype.exists = function () {
      return this.alpha >= 0.1 && this.size >= 1;
    };

    function Rocket(x) {
      Particle.apply(this, [
        {
          x: x,
          y: SCREEN_HEIGHT,
        },
      ]);

      this.explosionColor = 0;
    }

    Rocket.prototype = new Particle(0);
    Rocket.prototype.constructor = Rocket;

    Rocket.prototype.explode = function () {
      var count = Math.random() * 10 + 80;

      for (var i = 0; i < count; i++) {
        var particle = new Particle(this.pos);
        var angle = Math.random() * Math.PI * 2;

        // emulate 3D effect by using cosine and put more particles in the middle
        var speed = Math.cos((Math.random() * Math.PI) / 2) * 15;

        particle.vel.x = Math.cos(angle) * speed;
        particle.vel.y = Math.sin(angle) * speed;

        particle.size = 10;

        particle.gravity = 0.2;
        particle.resistance = 0.92;
        particle.shrink = Math.random() * 0.05 + 0.93;

        particle.flick = true;
        particle.color = this.explosionColor;

        particles.push(particle);
      }
    };

    Rocket.prototype.render = function (c) {
      if (!this.exists()) {
        return;
      }

      c.save();

      c.globalCompositeOperation = "lighter";

      var x = this.pos.x,
        y = this.pos.y,
        r = this.size / 2;

      var gradient = c.createRadialGradient(x, y, 0.1, x, y, r);
      gradient.addColorStop(0.1, "rgba(255, 255, 255 ," + this.alpha + ")");
      gradient.addColorStop(1, "rgba(0, 0, 0, " + "0" + ")");

      c.fillStyle = gradient;

      c.beginPath();
      c.arc(
        this.pos.x,
        this.pos.y,
        this.flick
          ? (Math.random() * this.size) / 2 + this.size / 2
          : this.size,
        0,
        Math.PI * 2,
        true
      );
      c.closePath();
      c.fill();

      c.restore();
    };
    this.overWriteCanvas();
  }
  overWriteCanvas() {
    let canvasClone = document.getElementsByTagName("canvas");
    console.log("canvasClone:", canvasClone);
    for (var i = 0; i < canvasClone.length; i++) {
      canvasClone[i].style.position = "absolute";
      canvasClone[i].style.top = "0px";
      canvasClone[i].style.zIndex = "1000000000";
    }
    setTimeout(() => {
      for (var i = 0; i < canvasClone.length; i++) {
        canvasClone[i].remove();
      }
    }, 7000);
  }
}
