import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { EntriesRoutingModule } from './entries-routing.module';
import { UserDataProvider } from 'src/app/Shared/UserDataProvider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from "@angular/cdk/drag-drop";
import { AllEntriesComponent } from './all-entries/all-entries.component';
import { ShortlistedEntriesComponent } from './shortlisted-entries/shortlisted-entries.component';
import { RejectedEntriesComponent } from './rejected-entries/rejected-entries.component';
import { ViewEntryComponent } from './view-entry/view-entry.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
@NgModule({
  declarations: [
                  AllEntriesComponent,
                  ShortlistedEntriesComponent,
                  RejectedEntriesComponent,
                  ViewEntryComponent
                ],
  imports: [
    CommonModule,
    EntriesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    NgxUiLoaderModule
    //MaterialModule
  ],
  providers: [UserDataProvider, DatePipe]
})
export class EntriesModule { }
