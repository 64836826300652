import { Injectable } from "@angular/core";
import { UserProvider } from "./UserProvider";
import { City, Week, Category } from "../Models/rewards";
import { UserService } from "../Service/user.service";
import {
  WeekEnttries,
  RatingModel,
  RatingParameter,
  Parameters,
  RankModel,
  GradeModel,
  gradeBasedData,
  WinnerList,
  DashboardModel,
  WinnerTypeModel,
} from "../Models/WeekEntries";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { privateKey, publicKey } from "src/app/Models/rsa-config";
import { JSEncrypt } from "jsencrypt";

@Injectable({
  providedIn: "root",
})
export class WeekEntriesProvider {
  selectedEntries: WeekEnttries;
  weekEntries: WeekEnttries[] = [];
  winnerListEntries: WeekEnttries[] = [];
  allWeeksEntries: WeekEnttries[] = [];
  allCompetationEntries: WeekEnttries[];
  cities: City[];
  $encrypt: any;
  weeks: Week[];
  position: any[];
  category: Category[];
  gradeEntries: gradeBasedData[] = [];
  ratings: RatingModel[];
  entriesRating: RatingModel[];
  ratingParameters: RatingParameter[];
  dashboardData: DashboardModel;
  winnerType: WinnerTypeModel[];
  rank: RankModel[] = [];
  grades: GradeModel[];
  constructor(
    public userService: UserService,
    private userProvider: UserProvider,
    private weekEntriesProvider: WeekEntriesProvider,
    public ngxLoader: NgxUiLoaderService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.$encrypt = new JSEncrypt();
    this.$encrypt.setPrivateKey(privateKey);
  }

  sumRating: any;

  getWeekEntries(): void {
    this.userService.getWeekEntriesData().subscribe((response) => {
      if (response.success) this.weekEntries = response.data;
      this.$encrypt.setPrivateKey(privateKey);
      if (this.weekEntries.length) {
        this.weekEntries.forEach((e) => {
          e.isConfirmed = false;
          e.isShortlisted = false;
          e.isRejected = false;
          e.notShortlist = false;
          if (e.moderator_internal_status == 9) {
            e.isConfirmed = true;
          }

          if (e.moderator_internal_status == 4 && e.winner_status != 1) {
            e.isShortlisted = true;
          }

          if (e.status == 2) {
            e.isHolded = true;
          }

          if (e.moderator_internal_status == 6) {
            e.isRejected = true;
          }

          e.winner_lists = this.winner_list;

          if (e.is_winner_one == 1) {
            e.winner_lists.find((w) => w.id == 1).selected = true;
          }

          if (e.moderator_internal_status == 9) {
            // let Rank: RankModel = new RankModel;
            // Rank.rank = e.rank;
            // this.rank.push(Rank);
          }

          e.isPDF = false;

          if (e.data_path) {
            let path = e.data_path.split(".");

            if (path[path.length - 1] == "pdf") {
              e.isPDF = true;
            }
          }

          e.ratingvalue = e.score;

          if (e.winner_lists.length > 0) {
            e.winner_lists.forEach((l) => {
              if (e.is_winner_one == 1) {
                e.winnerSelectedValue = 1;
                e.winnerValue = e.winnerSelectedValue;
              } else if (e.is_winner_two == 1) {
                e.winnerSelectedValue = 2;
                e.winnerValue = e.winnerSelectedValue;
              } else if (e.is_winner_three == 1) {
                e.winnerSelectedValue = 3;
                e.winnerValue = e.winnerSelectedValue;
              }
            });
          }
        });
      }
    });
  }

  UpdateWeekEntry(weekEntry: WeekEnttries): void {
    this.userService.updateWeekEntry(weekEntry).subscribe((response) => {
      if (response.success) {
        this.allCompetationEntries = response;
        if (this.allCompetationEntries.length > 0) {
          if (this.allCompetationEntries.length) {
            this.allCompetationEntries.forEach((e) => {
              e.isConfirmed = false;
              e.isShortlisted = false;
              e.isRejected = false;
              e.isHolded = false;
              e.notShortlist = false;
              e.winner_lists = this.winner_list;
              e.isPDF = false;
              e.isWinnerSelected = false;

              if (e.moderator_internal_status == 4) {
                e.isShortlisted = true;
              }

              if (e.moderator_internal_status == 6) {
                e.isRejected = true;
              }

              if (e.winner_id != null) {
                e.isConfirmed = true;
              }

              e.isPDF = false;

              if (e.data_path) {
                let path = e.data_path.split(".");

                if (path[path.length - 1] == "pdf") {
                  e.isPDF = true;
                }
              }
            });
          }
          this.shortlistedEntries = this.allCompetationEntries.filter(
            (a) => a.moderator_internal_status == 4 && a.winner_status != 1
          );
          this.rejectedEntries = this.allCompetationEntries.filter(
            (a) => a.moderator_internal_status == 6
          );
          console.log(this.shortlistedEntries);
        }
      }
    });
  }

  UpdateWeekEntry1(weekEntry: WeekEnttries, gradeId: number): void {
    this.userService.updateWeekEntry(weekEntry).subscribe((response) => {
      this.getCompetationEntries(gradeId);
      if (response.success) {
        this.allCompetationEntries = response;
        if (this.allCompetationEntries.length > 0) {
          if (this.allCompetationEntries.length) {
            this.allCompetationEntries.forEach((e) => {
              e.isConfirmed = false;
              e.isShortlisted = false;
              e.isRejected = false;
              e.isHolded = false;
              e.notShortlist = false;
              e.winner_lists = this.winner_list;
              e.isPDF = false;
              e.isWinnerSelected = false;
              if (e.moderator_internal_status == 4) {
                e.isShortlisted = true;
              }

              if (e.moderator_internal_status == 6) {
                e.isRejected = true;
              }

              if (e.winner_id != null) {
                e.isConfirmed = true;
              }

              e.isPDF = false;

              if (e.data_path) {
                let path = e.data_path.split(".");

                if (path[path.length - 1] == "pdf") {
                  e.isPDF = true;
                }
              }
            });
          }
          this.shortlistedEntries = this.allCompetationEntries.filter(
            (a) => a.moderator_internal_status == 4
          );
          this.rejectedEntries = this.allCompetationEntries.filter(
            (a) => a.moderator_internal_status == 6
          );
          console.log(this.shortlistedEntries);
        }
      }
    });
  }

  getWinnersEntries() {
    this.userService.getWeekEntriesData().subscribe((response) => {
      if (response.success) {
        this.allWeeksEntries = response.data;
        let userRole = localStorage.getItem("userRole");
        let user = localStorage.getItem("userId");
        if (parseInt(userRole) === 70) {
          this.allWeeksEntries = this.allWeeksEntries.filter(
            (w) => w.user_id == parseInt(user)
          );
        }
        if (this.allWeeksEntries.length) {
          this.allWeeksEntries.forEach((e) => {
            e.isConfirmed = false;
            e.isShortlisted = false;
            e.isRejected = false;
            e.isHolded = false;
            e.notShortlist = false;
            e.winner_lists = this.winner_list;
            e.isPDF = false;
            if (e.winner_lists.length > 0) {
              e.winner_lists.forEach((l) => {
                if (e.is_winner_one == 1) {
                  e.winnerSelectedValue = 1;
                  e.winnerValue = e.winnerSelectedValue;
                } else if (e.is_winner_two == 1) {
                  e.winnerSelectedValue = 2;
                  e.winnerValue = e.winnerSelectedValue;
                } else if (e.is_winner_three == 1) {
                  e.winnerSelectedValue = 3;
                  e.winnerValue = e.winnerSelectedValue;
                }
              });
            }

            if (e.moderator_internal_status == 9) {
              e.isConfirmed = true;
              e.editRating = true;
            }

            if (e.moderator_internal_status == 4 && e.winner_status != 1) {
              e.isShortlisted = true;
            }

            if (e.status == 2) {
              e.isHolded = true;
            }

            if (e.moderator_internal_status == 6) {
              e.isRejected = true;
            }

            e.isPDF = false;

            if (e.data_path) {
              let path = e.data_path.split(".");

              if (path[path.length - 1] == "pdf") {
                e.isPDF = true;
              }
            }

            let sumRating = 0;
            e.rating.forEach((r) => {
              sumRating += r.rating;
            });
            e.ratingvalue = sumRating;
          });
        }
      }
    });
  }

  getEntiesCity(): void {
    this.userService.getCityData().subscribe((response) => {
      this.cities = response.data;
    });
  }

  getWeekEntriesByStatus(item): void {
    let userRole = localStorage.getItem("userRole");
    let user = localStorage.getItem("userId");

    switch (item) {
      case "1":
        this.weekEntries = this.allWeeksEntries;
        break;
      case "2":
        this.weekEntries = this.allWeeksEntries.filter(
          (e) => e.moderator_internal_status == 4
        );
        break;
      case "3":
        this.weekEntries = this.allWeeksEntries.filter((e) => e.status == 2);
        break;
      case "5":
        this.weekEntries = this.allWeeksEntries.filter(
          (e) => e.moderator_internal_status == 6
        );
        break;
      case "4":
        this.weekEntries = this.allWeeksEntries.filter(
          (e) => e.moderator_internal_status == 9
        );
        break;
      case "6":
        this.weekEntries = this.allWeeksEntries.filter(
          (e) => e.moderator_internal_status == 3
        );
        break;
    }
  }

  getWeekEntriesByStatus1() {
    this.weekEntries = this.allWeeksEntries;
  }

  getEntiesWeeks(): void {
    this.userService.getWeeks().subscribe((response) => {
      this.weeks = response;
    });
  }

  getEntiesPosition(): void {
    this.userService.getPositionData().subscribe((response) => {
      this.position = response;
    });
  }

  getGrades() {
    this.userService.grades().subscribe((response) => {
      if (response.success) {
        this.grades = response.data;
      }
    });
  }

  getGradesData() {
    this.userService.grades().subscribe((response) => {
      if (response.success) {
        this.grades = response.data;
        if (this.grades.length > 0) {
          this.gradeEntries = [];
          this.grades.forEach((grade) => {
            let tabsData: gradeBasedData = new gradeBasedData();
            tabsData.title = grade.name;
            tabsData.content = this.weekEntries.filter(
              (w) => w.grade_id == grade.id
            );
            grade.id == 1
              ? (tabsData.active = true)
              : (tabsData.active = false);
            this.gradeEntries.push(tabsData);
          });
        }
      }
    });
  }

  getWinnerList() {
    this.userService.WinnerList().subscribe((response) => {
      if (response.success) {
        this.winnerListEntries = response.data;
        if (this.grades.length > 0) {
          this.gradeEntries = [];
          this.grades.forEach((grade) => {
            let tabsData: gradeBasedData = new gradeBasedData();
            tabsData.title = grade.name;
            tabsData.content = this.winnerListEntries.filter(
              (w) => w.grade_id == grade.id
            );
            grade.id == 1
              ? (tabsData.active = true)
              : (tabsData.active = false);
            this.gradeEntries.push(tabsData);
          });
        }
      }
    });
  }

  getEntriesCategory(): void {
    this.userService.getCategory().subscribe((response) => {
      this.category = response;
    });
  }

  getRatingOfEntries(): void {
    this.userService.getRatingEntries().subscribe((response) => {
      this.ratings = response;
    });
  }

  getRatingParameter(): void {
    this.userService.getRatingParameter().subscribe((response) => {
      this.ratingParameters = response;
      this.ratingParameters.forEach((r) => {
        r.paramter_id == r.id;
      });
    });
  }

  getSelectedEntryById(id: any) {
    this.selectedEntries = this.weekEntries.find((w) => w.id == id);
    return this.selectedEntries;
  }

  getCityBasedRecord(cityId) {
    if (cityId != -1) {
      this.weekEntries = this.allWeeksEntries.filter(
        (w) => w.city_id == parseInt(cityId)
      );
    }
  }

  getRankBasedRecord(rank) {
    this.weekEntries = this.allWeeksEntries.filter(
      (w) => w.rank == parseInt(rank)
    );
  }

  getDateBasedRecords(date1, date2) {
    let tempDate = new Date(date2);
    date2 = tempDate.setDate(tempDate.getDate() + 1);
    this.weekEntries = this.allWeeksEntries.filter(
      (m) =>
        new Date(m.submitted_date) >= new Date(date1) &&
        new Date(m.submitted_date) <= date2
    );
  }

  winner_list: WinnerList[] = [
    { id: 1, isdisabled: false, name: "Winner-1", selected: false },
    { id: 2, isdisabled: false, name: "Winner-2", selected: false },
    { id: 3, isdisabled: false, name: "Winner-3", selected: false },
  ];

  // New Functions

  getDashboardData(): void {
    this.userService.getDashboardDetails().subscribe((response) => {
      this.dashboardData = response;
    });
  }

  // Loader

  shortlistedEntries: WeekEnttries[];
  rejectedEntries: WeekEnttries[];

  getCompetationEntries(gradeId): void {
    this.ngxLoader.start();
    this.userService.getCompetationDetails(gradeId).subscribe((response) => {
      this.allCompetationEntries = response;
      if (this.allCompetationEntries.length > 0) {
        if (this.allCompetationEntries.length) {
          this.allCompetationEntries.forEach((e) => {
            e.isConfirmed = false;
            e.isShortlisted = false;
            e.isRejected = false;
            e.isHolded = false;
            e.notShortlist = false;
            e.winner_lists = this.winner_list;
            e.isPDF = false;
            e.isWinnerSelected = false;
            // e.customername = this.$encrypt.decrypt(e.customername);
            // e.data_path = this.$encrypt.decrypt(e.data_path);
            // e.grade_name = this.$encrypt.decrypt(e.grade_name);
            // e.customername = this.$encrypt.decrypt(e.customername);
            // e.submitted_date = this.$encrypt.decrypt(e.submitted_date);
            // e.competition_name = this.$encrypt.decrypt(e.competition_name);
            // e.theme = this.$encrypt.decrypt(e.theme);
            // e.username = this.$encrypt.decrypt(e.username);
            e.mobile = this.$encrypt.decrypt(e.mobile);
            if (e.moderator_internal_status == 4) {
              e.isShortlisted = true;
            }

            if (e.moderator_internal_status == 6) {
              e.isRejected = true;
            }

            if (e.winner_id != null) {
              e.isConfirmed = true;
            }

            e.isPDF = false;

            if (e.data_path) {
              let path = e.data_path.split(".");

              if (path[path.length - 1] == "pdf") {
                e.isPDF = true;
              }
            }
          });
        }
        this.shortlistedEntries = this.allCompetationEntries.filter(
          (a) => a.moderator_internal_status == 4 && a.winner_status != 1
        );
        this.rejectedEntries = this.allCompetationEntries.filter(
          (a) => a.moderator_internal_status == 6
        );
        console.log(this.shortlistedEntries);
      }
      this.ngxLoader.stop();
    });
  }

  getWinnerType() {
    this.userService.getWinnerType().subscribe((response) => {
      this.winnerType = response;
    });
  }

  getWinnerTypeBasedOnGrade(gradeId) {
    let winnerType = [];
    let selectedWinners = this.allCompetationEntries.filter(
      (w) => w.winner_id != null && w.grade_id == gradeId
    );
    console.log(selectedWinners);
    let result = selectedWinners.map((a) => a.winner_id);
    let result1 = this.winnerType.map((a) => a.id);
    const intersection = result1.filter((element) => !result.includes(element));
    if (intersection.length > 0) {
      intersection.forEach((i) => {
        let found = this.winnerType.findIndex((w) => w.id == i);
        if (found >= 0) {
          winnerType.push(this.winnerType.find((w) => w.id == i));
        }
      });
    }
    const dataArrWithSet = new Set(winnerType);
    const resultArr = [...dataArrWithSet];
    return [...resultArr];
  }
  isPublished: boolean;
  publishWinnerByGrade(gradeId: any, Winners: any) {
    this.isPublished = true;
    this.ngxLoader.start();
    this.userService.publishWinner(gradeId, Winners).subscribe(
      (response) => {
        if (response.data) {
          this.isPublished = false;
          this.getCompetationEntries(gradeId);
          this.ngxLoader.stop();
          this.toastr.success("Winner Published", "Success");
        }
      },
      (error) => {
        if (error.status == 422) {
          this.ngxLoader.stop();
          this.toastr.error(JSON.parse(error.data.message), "Error");
        }
      }
    );
  }
}
