import { Injectable } from '@angular/core';
import { UserService } from '../Service/user.service';
import { LoginForm, UserModel } from '../Models/login-form';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Rewards, WeeksBasedEntriesData } from '../Models/rewards';
import { privateKey, publicKey } from 'src/app/Models/rsa-config';
import { JSEncrypt } from 'jsencrypt';
@Injectable({
  providedIn: 'root'
})
export class UserProvider {
  public errorMessage: string;
  public loggedInUser: UserModel = new UserModel;
  public campaignRewards: Rewards;
  $encrypt =  new JSEncrypt();
  public rewardEntriesData: WeeksBasedEntriesData;
  public school_id : any;

  constructor(private userService: UserService,
    private toastr: ToastrService, private router: Router) {
      this.$encrypt.setPrivateKey(privateKey);
    }
  userLogin(LoginForm: LoginForm): void {
    this.userService.userLogin(LoginForm).subscribe(
      (response) => {

        if (response.success) {
          localStorage.setItem('userRole', '1');
          localStorage.setItem('userId', response.data.id);
          localStorage.setItem('userName', response.data.name);
          localStorage.setItem('mobile',(response.data.mobile));
          debugger
          localStorage.setItem('school_id', response.data.school_id);
          localStorage.setItem('access-token', response.data.access_token);
          this.loggedInUser.isAuditor = false;
          this.loggedInUser.isModerator = true;
          localStorage.setItem('loginUserData', JSON.stringify(this.loggedInUser.isModerator));
          this.router.navigate(["/otp"]);
        }
        else {
          localStorage.removeItem('access-token');
          localStorage.removeItem('userRole');
          localStorage.removeItem('userId');
        }

      },
      error => {
        if (error.status == 422) {
          this.toastr.error(" Incorrect username or password ", "Error");
          this.errorMessage = ' Incorrect username or password ';
        }
      }
    )
  };




}
