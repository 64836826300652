export enum EventType {
    areaChanged,
    subAreaChanged,
    insideSubAreadChanged,
    queryParamChanged,
}

export interface EventData {
    type: EventType;
    id?: string;
    data?: any;
    eventStatusHandler?: EventStatusHandler;
    name?: string;
}

export abstract class EventStatusHandler {
    public abstract EventUpdate(eventData: EventData): void;
}

export class PreviousRouterData {
    eventName: string;
    eventCount: number;
    eventStatus : any;
    task: boolean;
}
