
export class WeekEnttries {
    order?: number;
    assigned_on: string;
    customername: string;
    data_path: string;
    points: number;
    rating: RatingParameter[] = [];
    isShortlisted: boolean;
    status: number;
    isRejected: boolean;
    isConfirmed: boolean;
    submitted_date: string;
    user_id: number;
    winningtime: string;
    moderator_internal_status: number;
    id: number;
    comments: ModeratorComments[] = [];
    ratingvalue: any;
    city_id: number;
    description: string;
    cityname: string;
    title: string;
    isHolded: boolean;
    selectedInput: number;
    isPDF: boolean;
    notShortlist: boolean;
    relase: boolean;
    previous_moderator_internal_status: number;
    rank: any;
    editRating:boolean = false;
    username: string;
    disableRating : boolean;
    score : number;
    grade_id : number;
    competition_name: string;
    theme : string;
    grade_name: string;
    roll_number: string;
    winner_lists : WinnerList[];
    is_winner_one : number;
    is_winner_two : number;
    is_winner_three : number;
    winnerSelectedValue:number;
    winnerValue : number;
    winner_id : number;
    isWinnerSelected: boolean;
    winner_status: number;
    mobile : string;
    competition_id: number;
    school_name: string;
}

export class RatingModel {
    id: number;
    rating: number;
    contact: string;
}

export class RatingParameter {
    id: number;
    name: string;
    rating: any;
    paramter_id: number;
}

export class gradeBasedData {
  title: string;
  content: WeekEnttries[];
  active: boolean;
}

export class WinnerList {
  id : number;
  isdisabled : boolean;
  name : string;
  selected:boolean;
}

export class configPagination {
    currentPage: any;
    itemsPerPage: any;
}

export class Parameters {
    id: number;
    paramter_id: number;
    name: string;
    rating: number;
    status: number;
    created_date: string;
    updated_date: string;
    campaign_id: number;
}

export class ModeratorComments {
    id: number;
    comments: string;
    created_date: string;
    moderator_name: string;
    winnerlist_id: number;
    user_id: number;
}

export class RankModel{
    rank : string;
}

export class GradeModel {
  id : number;
  name : string
}

export class DashboardModel {
  total_count : string;
  shortlisted_count : string;
  rejected_count : string;
}

export class WinnerTypeModel {
  id : number;
  name : string;
  selected: boolean;
}

