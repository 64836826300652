import { Component, Input, Output, EventEmitter, OnInit, Directive,
  ElementRef,
  HostListener, } from '@angular/core';

@Component({
  selector: 'app-sample-rating',
  templateUrl: './sample-rating.component.html',
  styleUrls: ['./sample-rating.component.scss']
})
export class SampleRatingComponent implements OnInit {
  public value;
  @Input() rating: number;
  @Input() itemId: number;
  @Output() ratingClick: EventEmitter<any> = new EventEmitter<any>();

  inputName: string;

  ngOnInit() {
    this.inputName = this.itemId + '_rating';
  }

  onClick(rating: number): void {
    console.log(rating)
    if(rating <= 10) {
      this.rating = rating;
      this.ratingClick.emit({
        itemId: this.itemId,
        rating: rating
      });
    }
    else{
      this.value = this.rating;
    }
  }

}
