import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WeekEnttries } from 'src/app/Models/WeekEntries';
import { WeekEntriesProvider } from 'src/app/Providers/WeekEntriesProvider';

@Component({
  selector: 'app-rejected-entries',
  templateUrl: './rejected-entries.component.html',
  styleUrls: ['./rejected-entries.component.scss']
})
export class RejectedEntriesComponent implements OnInit {
  restore: boolean;
  grade: number;

  // TODO: Cross browsing0
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  allEntries : WeekEnttries[];
  shortlistedEntries: WeekEnttries[];
  rejectedEntries: WeekEnttries[];
  item: number;
  isShow: boolean;
  gradeNumber : any;
  @Input() model: WeekEnttries = new WeekEnttries;
  selectedInput:number = 3;

  constructor(private activateRoute: ActivatedRoute,
    private router : Router,
    private weekEntriesProvider: WeekEntriesProvider) {
      (document.querySelector('.footer') as HTMLElement).style.position = 'fixed';
  }

  ngOnInit() {
    this.activateRoute.paramMap.subscribe(paramMap => {
      if (!paramMap.has('gradeId')) {
          this.router.navigateByUrl('/entries')
      }
      else{
        this.gradeNumber = paramMap.get('gradeId');
        this.grade = +this.gradeNumber + 1;
        console.log(`get the entries based on the grade ${paramMap.get('gradeId')}`);
        this.weekEntriesProvider.getCompetationEntries(paramMap.get('gradeId'));
      }
    });
  }

  ngDoCheck() {
    this.allEntries = this.weekEntriesProvider.allCompetationEntries;
    this.shortlistedEntries = this.weekEntriesProvider.shortlistedEntries;
    this.rejectedEntries = this.weekEntriesProvider.rejectedEntries;
  }

  ViewEntry(item) {
    this.router.navigate(['/entries/view-entry'], {queryParams: {gradeId: this.gradeNumber, entryId: item.id}});
  }

  backClicked() {
    this.router.navigate(['/entries/dashboard']);
  }

  onNavigate(item) {
    switch(item) {
      case "1" :
          this.router.navigate(['/entries/all-entries', this.gradeNumber]);
          break;
      case "2" :
          this.router.navigate(['/entries/shortlisted-entries', this.gradeNumber]);
          break;
      case "3" :
        this.router.navigate(['/entries/rejected-entries', this.gradeNumber]);
        break;
    }
  }

  restoreClicked(entries: WeekEnttries) {
    this.restore = true;
    this.item = entries.id;
  }

  restoreApproved(entries: WeekEnttries) {
    this.restore = false;
    this.item = null;
    this.model = this.allEntries.find(w => w.id === entries.id);
    this.model.moderator_internal_status = 3;
    this.model.moderator_internal_status = this.allEntries.find(w => w.id === entries.id).previous_moderator_internal_status;
    this.model.isRejected = this.allEntries.find(w => w.id == entries.id).isRejected = false;
    this.weekEntriesProvider.UpdateWeekEntry1(this.model, this.model.grade_id);
  }

  notRestore(entries: WeekEnttries) {
    this.restore = false;
    this.item = null;
  }

}
