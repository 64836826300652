import { trigger, transition, query, stagger, animate, style } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WeekEnttries } from 'src/app/Models/WeekEntries';
import { WeekEntriesProvider } from 'src/app/Providers/WeekEntriesProvider';

@Component({
  selector: 'app-all-entries',
  templateUrl: './all-entries.component.html',
  styleUrls: ['./all-entries.component.scss'],
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(100, [
            animate('0.2s', style({ opacity: 0 }))
          ])
        ],
          { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.2s', style({ opacity: 1 }))
          ])
        ], { optional: true })
      ])
    ])
  ]
})


export class AllEntriesComponent implements OnInit {

  // TODO: Cross browsing0
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  allEntries : WeekEnttries[];
  shortlistedEntries: WeekEnttries[];
  rejectedEntries: WeekEnttries[];
  gradeNumber : any;
  grade : any;
  item: number;
  isShow: boolean;
  rejectedClick: boolean = false;
  restore : boolean = false;
  @Input() model: WeekEnttries = new WeekEnttries;
  selectedInput:number = 1;
  //Button Section;
  shortlistRemove: boolean = false;
  shortlistedConfirm :boolean = false;
  rejectedConfirm :boolean = false;
  constructor(private activateRoute: ActivatedRoute,
    private router : Router,
    private weekEntriesProvider: WeekEntriesProvider) {
      (document.querySelector('.footer') as HTMLElement).style.position = 'fixed';
  }

  ngOnInit() {
    this.activateRoute.paramMap.subscribe(paramMap => {
      if (!paramMap.has('gradeId')) {
          this.router.navigateByUrl('/entries')
      }
      else{
        this.gradeNumber = parseInt(paramMap.get('gradeId'));
        this.grade = +this.gradeNumber + 1;
        console.log(`get the entries based on the grade ${paramMap.get('gradeId')}`);
         this.weekEntriesProvider.getCompetationEntries(paramMap.get('gradeId'));
      }
    });

  }

  ngDoCheck() {
    this.allEntries = this.weekEntriesProvider.allCompetationEntries;
    this.shortlistedEntries = this.weekEntriesProvider.shortlistedEntries;
    this.rejectedEntries = this.weekEntriesProvider.rejectedEntries;
    console.log("shortlistedEntries::",this.shortlistedEntries);

  }

  backClicked() {
    this.router.navigate(['/entries/dashboard']);
  }

  onNavigate(item) {

    switch(item) {
      case "1" :
          this.router.navigate(['/entries/all-entries', this.gradeNumber]);
          break;
      case "2" :
          this.router.navigate(['/entries/shortlisted-entries', this.gradeNumber]);
          break;
      case "3" :
        this.router.navigate(['/entries/rejected-entries', this.gradeNumber]);
        break;
    }

  }

  ViewEntry(item) {
    this.router.navigate(['/entries/view-entry'], {queryParams: {gradeId: this.gradeNumber, entryId: item.id}});
  }

  // Shortlist Button

  shortListClicked(entries: WeekEnttries) {
    this.item = entries.id;
    this.model.isShortlisted = this.allEntries.find(w => w.id == entries.id).isShortlisted = true;
    this.shortlistedConfirm = true;
    this.model = this.allEntries.find(w => w.id == entries.id);
  }

  shortlistApproved(entries: WeekEnttries) {
    this.item = null;
    this.allEntries.find(w => w.id == entries.id).isShortlisted = true;
    this.model.previous_moderator_internal_status = this.allEntries.find(w => w.id == entries.id).moderator_internal_status;
    this.shortlistedConfirm = false;
    this.model.moderator_internal_status = 4;
    this.weekEntriesProvider.UpdateWeekEntry1(this.model, parseInt(this.gradeNumber));
  }

  notshortListed(entries) {
    this.item = null;
    this.model.isShortlisted = this.allEntries.find(w => w.id == entries.id).isShortlisted = false;
    this.shortlistedConfirm = false;
    this.model = this.allEntries.find(w => w.id == entries.id);
  }

  shortistRemove(entries: WeekEnttries) {
    this.model.isShortlisted = this.allEntries.find(w => w.id == entries.id).isShortlisted = true;
    this.shortlistRemove = true;
    this.item = entries.id;
    this.model = this.allEntries.find(w => w.id == entries.id);
  }

  shortistRemoveConfirmed(entries: WeekEnttries) {
    this.model.previous_moderator_internal_status = this.allEntries.find(w => w.id == entries.id).moderator_internal_status
    this.model.isShortlisted = this.allEntries.find(w => w.id == entries.id).isShortlisted = false;
    this.model.moderator_internal_status = 3;
    this.shortlistRemove = false;
    this.item = null;
    this.weekEntriesProvider.UpdateWeekEntry1(this.model, parseInt(this.gradeNumber));

  }

  shortistRemoveCancelled(entries: WeekEnttries) {
    this.model.isShortlisted = this.allEntries.find(w => w.id == entries.id).isShortlisted = true;
    this.shortlistRemove = false;
    this.item = null;
    this.model = this.allEntries.find(w => w.id == entries.id);
  }

  /************************************************************************************************* */
  // Rejected Entries

  rejectedClicked(entries) {
    this.rejectedClick = true;
    this.item = entries.id;
    this.allEntries.find(w => w.id === entries.id).isRejected = true;
    this.model = this.allEntries.find(w => w.id == entries.id);
  }

  restoreClicked(entries: WeekEnttries) {
    this.restore = true;
    this.item = entries.id;
  }

  restoreApproved(entries: WeekEnttries) {
    this.restore = false;
    this.item = null;
    this.model = this.allEntries.find(w => w.id === entries.id);
    this.model.moderator_internal_status = this.allEntries.find(w => w.id === entries.id).previous_moderator_internal_status;
    this.model.isRejected = this.allEntries.find(w => w.id == entries.id).isRejected = false;
    this.weekEntriesProvider.UpdateWeekEntry1(this.model, parseInt(this.gradeNumber));
  }

  rejectedApproved(entries) {
    this.item = null;
    this.model.previous_moderator_internal_status = this.allEntries.find(w => w.id == entries.id).moderator_internal_status
    this.allEntries.find(w => w.id == entries.id).isRejected = true;
    this.model = this.allEntries.find(w => w.id == entries.id);
    this.model.status = 3;
    this.model.winner_id = null;
    this.model.moderator_internal_status = 6;
    this.weekEntriesProvider.UpdateWeekEntry1(this.model, parseInt(this.gradeNumber));
  }

  notRejected(entries) {
    this.model.isRejected = this.allEntries.find(w => w.id == entries.id).isRejected = false;
    this.model = this.allEntries.find(w => w.id == entries.id);
    this.rejectedClick = false;
    this.item = null;
  }


  notRestore(entries: WeekEnttries) {
    this.restore = false;
    this.item = null;
  }

  /************************************************************************************************* */

  ngOnDestroy() {
    if (this.allEntries) {
        this.allEntries = [];
        this.shortlistedEntries = [];
        this.rejectedEntries = [];
    }
  }

}
