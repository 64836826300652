import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { RouteDataProvider } from 'src/app/Shared/RouteDataProvider.provider';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ICompany } from 'src/app/Models/rewards';
import { Parameters, RatingParameter } from 'src/app/Models/WeekEntries';
import { WeekEntriesProvider } from 'src/app/Providers/WeekEntriesProvider';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  currentRouter: string;
  modalRef: BsModalRef;
  score: any;
  itemScores: ICompany[];
  parameters: RatingParameter[];
  config = {
    animated: true,
    backdrop: false
  };
  ratingValidationMessage: string;
  constructor(public routerDataProviders: RouteDataProvider, public route: Router,
    private toastr: ToastrService,
    private modalService: BsModalService,
    public weekEntriesData: WeekEntriesProvider) {
    this.routerDataProviders.area.subscribe(
      m => {
        this.currentRouter = m;
        console.log(this.currentRouter)
      }
    )

    this.routerDataProviders.subArea.subscribe(
      m => {
        this.currentRouter = m;
        console.log(this.currentRouter)
      }
    )
  }

  ngOnInit() {

  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  ngDocheck() {
    this.parameters = this.weekEntriesData.ratingParameters;
    console.log(this.parameters);
  }


  logout() {
    this.toastr.success("Logout", "Success");
    let authKey = localStorage.getItem('auth_key');
    this.route.navigateByUrl('/login?id='+authKey);
    localStorage.clear();
  }

  ratingClicked: number;
  itemIdRatingClicked: string;

  items: ICompany[] = [
    { 'id': 0, 'rating': 0, 'contact': 'Creativity', 'company': 'PROFLEX' },
    { 'id': 1, 'rating': 0, 'contact': 'Sustainability', 'company': 'CENTREXIN' },
    { 'id': 2, 'rating': 0, 'contact': 'Usage of Colours', 'company': 'JIMBIES' },
    { 'id': 3, 'rating': 0, 'contact': 'Photography', 'company': 'TERRAGO' },
    { 'id': 4, 'rating': 0, 'contact': 'Nature Photography', 'company': 'ISOTRONIC' }
  ];

  ratingComponentClick(clickObj: any): void {
    this.ratingValidationMessage = '';
    this.score = 0;
    const item = this.items.find(((i: any) => i.id === clickObj.itemId));
    console.log(item);
    if (!!item) {
      this.items.forEach(
        r => {
          if (r.id === clickObj.itemId) {
              r.rating = parseFloat(clickObj.rating);
          }
        }
      );

      console.log(this.items);
      this.score = this.items.reduce((accum,item) => accum + item.rating, 0);
      this.score = this.score.toFixed(1);
    }
  }


  cancel() {
    this.modalRef.hide();
  }

  submitRating(template1: TemplateRef<any>) {

    this.items.forEach(
      p => {
        if (p.rating == 0) {
          this.ratingValidationMessage = 'Please rate the image to confim';
          return;
        }
      }
    )

    if (this.ratingValidationMessage == '') {
      this.modalRef.hide();
      this.modalRef = this.modalService.show(template1, this.config);
      this.items.forEach(
        p => {
          p.rating = 0;
        }
      )
      this.score = undefined;
    }
  }

}
