import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './Components/layout/layout.component';
import { OtpComponent } from './Components/otp/otp.component';
import { AuthGuard } from './Shared/AuthGuard';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './Components/login/login.module#LoginModule'
      },
      {
        path: 'otp',
        loadChildren: './Components/otp/otp.module#OtpModule'
      },
      {
        path: '',
        // canActivate: [AuthGuard],
        loadChildren: './Components/Entries/entries.module#EntriesModule'
      },
      {
        path: '',
        // canActivate: [AuthGuard],
        loadChildren: './Components/Tabs/tabs.module#TabsModules'
      }, // redirect to `first-component`
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
