import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AllEntriesComponent } from './all-entries/all-entries.component';
import { RejectedEntriesComponent } from './rejected-entries/rejected-entries.component';
import { ShortlistedEntriesComponent } from './shortlisted-entries/shortlisted-entries.component';
import { ViewEntryComponent } from './view-entry/view-entry.component';

const routes: Routes = [
  {
    path: 'entries',
    children:[
      {
        path: 'dashboard',
        loadChildren: './dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'entries-data',
        loadChildren: './entries-data/entries-data.module#EntriesDataModule'
      },
      {
        path: 'all-entries/:gradeId',
        component:AllEntriesComponent
      },
      {
        path: 'shortlisted-entries/:gradeId',
        component:ShortlistedEntriesComponent
      },
      {
        path: 'rejected-entries/:gradeId',
        component:RejectedEntriesComponent
      },
      {
        path: 'view-entry',
        component:ViewEntryComponent
      },
      { path: '',   redirectTo: 'dashboard', pathMatch: 'full' },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EntriesRoutingModule { }
