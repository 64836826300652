import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './Components/layout/layout.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { EntriesModule } from './Components/Entries/entries.module';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteDataProvider } from './Shared/RouteDataProvider.provider';
import { FormsModule } from '@angular/forms';
import { RatingModule } from "ngx-rating";
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AuthGuard } from './Shared/AuthGuard';
import { LayoutModule } from './Components/layout/layout.module';
import { SampleRatingComponent } from './Components/Tabs/sample-rating/sample-rating.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { CacheInterceptor } from './Service/Http/cache.interceptor';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NumericDirective } from './Shared/numberDirective';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    SampleRatingComponent,
    NumericDirective

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutModule,
    EntriesModule,
    FormsModule,
    RatingModule,
    NgxUiLoaderModule,
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    ToastrModule.forRoot({
      maxOpened: 1,
      autoDismiss: true
    }),
    BsDatepickerModule.forRoot(),
    DragDropModule
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    RouteDataProvider, AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
