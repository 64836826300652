import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WeekEnttries, WinnerTypeModel } from 'src/app/Models/WeekEntries';
import { WeekEntriesProvider } from 'src/app/Providers/WeekEntriesProvider';
import { Location } from '@angular/common';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { TemplateRef } from '@angular/core';
@Component({
  selector: 'app-view-entry',
  templateUrl: './view-entry.component.html',
  styleUrls: ['./view-entry.component.scss'],
  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
    trigger('rotatedState', [
        state('default', style({ transform: 'rotate(0)' })),
        state('rotated', style({ transform: 'rotate(90deg)' })),
        state('defaul', style({ transform: 'rotate(180deg)' })),
        state('rotate', style({ transform: 'rotate(270deg)' })),
        state('rotatee', style({ transform: 'rotate(360deg)' })),
        transition('default => rotated', animate('400ms ease-in')),
        transition('rotated => defaul', animate('400ms ease-in')),
        transition('defaul => rotate', animate('400ms ease-in')),
        transition('rotate=>rotatee', animate('400ms ease-in')),
    ]),
    trigger('rotatedState1', [
        state('default', style({ transform: 'rotate(0)' })),
        state('rotated', style({ transform: 'rotate(90deg)' })),
        state('defaul', style({ transform: 'rotate(180deg)' })),
        state('rotate', style({ transform: 'rotate(270deg)' })),
        state('rotatee', style({ transform: 'rotate(360deg)' })),
        transition('default => rotated', animate('400ms ease-in')),
        transition('rotated => defaul', animate('400ms ease-in')),
        transition('defaul => rotate', animate('400ms ease-in')),
        transition('rotate=>rotatee', animate('400ms ease-in')),
    ]),
  ]
})
export class ViewEntryComponent implements OnInit {
  state1: string;
  grade: number;
  // TODO: Cross browsing
  gotoTop() {
    window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
    });
}
  entryId : string;
  state: string = 'default';
  gradeNumber: number;
  @Input() model: WeekEnttries = new WeekEnttries;
  allEntries: WeekEnttries[];
  constructor(private activateRoute: ActivatedRoute,
    private router : Router,
    private weekEntriesProvider: WeekEntriesProvider,
    private _location: Location,
    public datepipe: DatePipe,
    private modalService: BsModalService) {

    }

  ngOnInit() {
    this.activateRoute.queryParams.subscribe(paramMap => {
      if (!paramMap['entryId'] && !paramMap['gradeId']) {
          this.router.navigateByUrl('/entries')
      }
      else{
        this.entryId = paramMap['entryId'];
        this.gradeNumber = paramMap['gradeId'];
        this.grade = +this.gradeNumber + 1;
        console.log(`get the entries based on the grade ${paramMap['gradeId']}`);
        this.weekEntriesProvider.getCompetationEntries(paramMap['gradeId']);
        this.weekEntriesProvider.getWinnerType();
      }
    });
  }

  ngDoCheck() {
    this.allEntries = this.weekEntriesProvider.allCompetationEntries;
    if(typeof this.allEntries !="undefined") {
      if(this.allEntries.length > 0) {
        this.model = this.allEntries.find(w => w.id == parseInt(this.entryId));
        console.log(this.model);
      }
    }
  }


  backClicked() {
    this._location.back();
  }


  rotate() {
    this.state = (this.state === 'default') ? "rotated" : (this.state === 'rotated') ? "defaul" : (this.state === 'defaul') ? "rotate" : (this.state === 'rotate') ? "rotatee" : "default";
  }

  rotate1() {
    this.state1 = (this.state1 === 'default') ? "rotated" : (this.state1 === 'rotated') ? "defaul" : (this.state1 === 'defaul') ? "rotate" : (this.state1 === 'rotate') ? "rotatee" : "default";
  }

  zoom: number = 100;

  zoomIn() {
      this.zoom = this.zoom == 200 ? this.zoom : this.zoom + 10;
  }
  zoomOut() {
      this.zoom = this.zoom == 100 ? this.zoom : this.zoom - 10;
  }

  /****************************************************************************** */
  modalRef: BsModalRef;
  modalRef1: BsModalRef;
  winnerType: WinnerTypeModel[];

  shortListClicked(template5: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template5);
  }

  SelectWinners(template1: TemplateRef<any>) {
    this.modalRef1 = this.modalService.show(template1);
    this.model.isWinnerSelected = true;
    this.winnerType = this.weekEntriesProvider.getWinnerTypeBasedOnGrade(this.gradeNumber);
  }

  shortlistentry(entries: WeekEnttries) {
    this.model.isShortlisted = this.allEntries.find(w => w.id == entries.id).isShortlisted = true;
    this.model = this.allEntries.find(w => w.id == entries.id);
    this.model.moderator_internal_status = 4;
    this.modalRef.hide();
    this.weekEntriesProvider.UpdateWeekEntry(this.model);
    this.weekEntriesProvider.getCompetationEntries(this.gradeNumber);
    this.router.navigate(['/entries/all-entries', this.gradeNumber]);
  }

  selectWinner(item) {
    this.model.winner_id = item;
  }

  submitWinnerSelection(entries) {
    if(entries) {
      this.model.isWinnerSelected  = true;
      this.model.moderator_internal_status = 4;
      this.model.isShortlisted = true;
      this.model.winner_id = entries.winner_id;
      this.weekEntriesProvider.UpdateWeekEntry(this.model);
      this.weekEntriesProvider.getCompetationEntries(this.gradeNumber);
      this.modalRef1.hide();
    }
  }

  notSubmitWinner(entries) {
    this.model.isWinnerSelected = this.allEntries.find(w => w.id == entries.id).isShortlisted = false;
    this.modalRef1.hide();
  }

  resetEntry(entries){
    this.model = this.allEntries.find(w => w.id == entries.id);
    this.model.winner_id = null;
    this.model.isConfirmed = false;
    this.weekEntriesProvider.UpdateWeekEntry1(this.model, this.model.grade_id);
  }

  rejectedClicked(template3: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template3);
  }

  rejectentry(entries: WeekEnttries) {
    this.model.isRejected = this.allEntries.find(w => w.id == entries.id).isRejected = true;
    this.model = this.allEntries.find(w => w.id == entries.id);
    this.model.previous_moderator_internal_status = this.allEntries.find(w => w.id == entries.id).moderator_internal_status;
    this.model.moderator_internal_status = 6;
    this.weekEntriesProvider.UpdateWeekEntry(this.model);
    this.modalRef.hide();
    this.router.navigate(['/entries/all-entries']);
  }

}
