export class LoginForm{
    username : string;
    password: any;
    mobile : string;
    school: number;
    campaign_id : 1
}

export class OTPModel {
  mobile : string;
  otp: string;
  campaign_id : 1
}

export class UserModel {
    isModerator: boolean = true;
    isAuditor: boolean = false;
    Token: string;
    campaignId : number;
}
